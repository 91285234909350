/*
    ===============================
    1. SETTINGS
    ===============================
*/
/*
* By filling in the lists below, you will automatically generate utility colour and background colour classes for all the colour variables.
* The $colours list should be a space separated list of names; these will be used in the naming of the class i.e .u-black
* The $_colour_var should list all the variables you want to include
*
* The classes are generated within utilities.scss and will produce both .u-black and .u-black-bg (for example)
*/
/*
    ===============================
    2. TOOLS
    ===============================
*/
/*
    ===============================
    COMMON CODE
    ===============================
*/
/*
    ===============================
    TYPOGRAPHY
    ===============================
*/
/* Font families */
/* Body text */
/*
    ===============================
    SVG SPRITE AND FALLBACK
    ===============================
*/
/*
    ===============================
    GRID OUTER PADDING
    ===============================
*/
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/*
    ===============================
    3. RESET
    ===============================
*/
/* * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/) * http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Reset styling on inputs */
input {
  margin: 0;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=url],
input[type=search],
input[type=button],
input[type=reset],
input[type=submit],
input[type=search] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  cursor: pointer;
}

/*
    ===============================
    4. BASE
    ===============================
*/
body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  background: #FFFAE3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
}

main {
  background-color: #FFFFFF;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.625em;
}

h3 {
  font-size: 1.375em;
}

h4 {
  font-size: 1.125em;
}

h5, h6 {
  font-size: 1em;
}

p {
  font-size: 1.25em;
}

a {
  color: inherit;
  text-decoration: none;
}

a,
p a,
ol li a,
ul li a {
  cursor: pointer;
  text-decoration: none;
}
a:hover, a:focus,
p a:hover,
p a:focus,
ol li a:hover,
ol li a:focus,
ul li a:hover,
ul li a:focus {
  outline: 0;
  text-decoration: none;
}

b, strong, body.webkit strong, body.webkit b {
  font-weight: 700;
}

i, em {
  font-style: italic;
}

button, input, textarea, select {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
}

input[type=text],
input[type=email] {
  border: 0;
  box-sizing: border-box;
  display: inline-block;
  outline: 0;
  vertical-align: top;
}

/*
    ===============================
    5. OBJECTS
    ===============================
*/
/* #General body
================================================== */
.ts-body, .ui-autocomplete.ui-menu .ui-menu-item, .c-country-dropdown.ui-autocomplete-input, .ts-label, .ts-button-text {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .ts-body, .ui-autocomplete.ui-menu .ui-menu-item, .c-country-dropdown.ui-autocomplete-input, .ts-label, .ts-button-text {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .ts-body, .ui-autocomplete.ui-menu .ui-menu-item, .c-country-dropdown.ui-autocomplete-input, .ts-label, .ts-button-text {
    font-size: 1em;
  }
}
.ts-body a, .ui-autocomplete.ui-menu .ui-menu-item a, .c-country-dropdown.ui-autocomplete-input a, .ts-label a, .ts-button-text a {
  color: #5C0055;
  text-decoration: underline;
  transition: color 0.3s;
}
.ts-body a::selection, .ui-autocomplete.ui-menu .ui-menu-item a::selection, .c-country-dropdown.ui-autocomplete-input a::selection, .ts-label a::selection, .ts-button-text a::selection {
  background: rgba(255, 203, 0, 0.99);
  color: #000000;
}
.ts-body a:hover, .ui-autocomplete.ui-menu .ui-menu-item a:hover, .c-country-dropdown.ui-autocomplete-input a:hover, .ts-label a:hover, .ts-button-text a:hover, .ts-body a:focus, .ui-autocomplete.ui-menu .ui-menu-item a:focus, .c-country-dropdown.ui-autocomplete-input a:focus, .ts-label a:focus, .ts-button-text a:focus {
  color: #000000;
  text-decoration: underline;
}
.ts-body a.c-tooltip, .ui-autocomplete.ui-menu .ui-menu-item a.c-tooltip, .c-country-dropdown.ui-autocomplete-input a.c-tooltip, .ts-label a.c-tooltip, .ts-button-text a.c-tooltip {
  text-decoration: none;
}
.ts-body ul::selection, .ui-autocomplete.ui-menu .ui-menu-item ul::selection, .c-country-dropdown.ui-autocomplete-input ul::selection, .ts-label ul::selection, .ts-button-text ul::selection, .ts-body ol::selection, .ui-autocomplete.ui-menu .ui-menu-item ol::selection, .c-country-dropdown.ui-autocomplete-input ol::selection, .ts-label ol::selection, .ts-button-text ol::selection, .ts-body li::selection, .ui-autocomplete.ui-menu .ui-menu-item li::selection, .c-country-dropdown.ui-autocomplete-input li::selection, .ts-label li::selection, .ts-button-text li::selection, .ts-body strong::selection, .ui-autocomplete.ui-menu .ui-menu-item strong::selection, .c-country-dropdown.ui-autocomplete-input strong::selection, .ts-label strong::selection, .ts-button-text strong::selection, .ts-body em::selection, .ui-autocomplete.ui-menu .ui-menu-item em::selection, .c-country-dropdown.ui-autocomplete-input em::selection, .ts-label em::selection, .ts-button-text em::selection, .ts-body u::selection, .ui-autocomplete.ui-menu .ui-menu-item u::selection, .c-country-dropdown.ui-autocomplete-input u::selection, .ts-label u::selection, .ts-button-text u::selection {
  background: #FFCB00;
  color: #000000;
}
.ts-body::selection, .ui-autocomplete.ui-menu .ui-menu-item::selection, .c-country-dropdown.ui-autocomplete-input::selection, .ts-label::selection, .ts-button-text::selection {
  background: #FFCB00;
  color: #000000;
}
.ts-body--small, .optanon-cookie-policy-group-description, .optanon-cookie-policy-group-cookies-list, .optanon-cookie-policy-subgroup-table, td.optanon-cookie-policy-left, td.optanon-cookie-policy-right, .optanon-cookie-policy-subgroup-name, .optanon-cookie-policy-subgroup-cookies-list li {
  font-size: 1em;
}
.ts-body--semi-bold {
  font-weight: 600;
}

/* #Headings
================================================== */
.ts-heading-1 {
  color: #FFFAE3;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .ts-heading-1 {
    font-size: 2.375em;
  }
}
@media screen and (max-width: 767px) {
  .ts-heading-1 {
    font-size: 2em;
  }
}
.ts-heading-1::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.ts-heading-1 strong::selection, .ts-heading-1 em::selection, .ts-heading-1 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}

.ts-heading-2 {
  font-size: 2.25em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .ts-heading-2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 767px) {
  .ts-heading-2 {
    font-size: 1.625em;
  }
}
.ts-heading-2::selection {
  background: #FFCB00;
  color: #000000;
}

.ts-heading-3, .optanon-cookie-policy-group-name {
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .ts-heading-3, .optanon-cookie-policy-group-name {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 767px) {
  .ts-heading-3, .optanon-cookie-policy-group-name {
    font-size: 1.375em;
  }
}
.ts-heading-3::selection, .optanon-cookie-policy-group-name::selection {
  background: #FFCB00;
  color: #000000;
}
.ts-heading-3 strong::selection, .optanon-cookie-policy-group-name strong::selection, .ts-heading-3 em::selection, .optanon-cookie-policy-group-name em::selection, .ts-heading-3 u::selection, .optanon-cookie-policy-group-name u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}

.ts-heading-4 {
  font-size: 1.375em;
  font-weight: 700;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .ts-heading-4 {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 767px) {
  .ts-heading-4 {
    font-size: 1.125em;
  }
}
.ts-heading-4::selection {
  background: #FFCB00;
  color: #000000;
}
.ts-heading-4 strong::selection, .ts-heading-4 em::selection, .ts-heading-4 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}

.ts-heading-5, .optanon-cookie-policy-subgroup-table-column-header, .optanon-cookie-policy-cookies-used, th.optanon-cookie-policy-left, th.optanon-cookie-policy-right {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .ts-heading-5, .optanon-cookie-policy-subgroup-table-column-header, .optanon-cookie-policy-cookies-used, th.optanon-cookie-policy-left, th.optanon-cookie-policy-right {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .ts-heading-5, .optanon-cookie-policy-subgroup-table-column-header, .optanon-cookie-policy-cookies-used, th.optanon-cookie-policy-left, th.optanon-cookie-policy-right {
    font-size: 1em;
  }
}
.ts-heading-5::selection, .optanon-cookie-policy-subgroup-table-column-header::selection, .optanon-cookie-policy-cookies-used::selection, th.optanon-cookie-policy-left::selection, th.optanon-cookie-policy-right::selection {
  background: #FFCB00;
  color: #000000;
}
.ts-heading-5 strong::selection, .optanon-cookie-policy-subgroup-table-column-header strong::selection, .optanon-cookie-policy-cookies-used strong::selection, th.optanon-cookie-policy-left strong::selection, th.optanon-cookie-policy-right strong::selection, .ts-heading-5 em::selection, .optanon-cookie-policy-subgroup-table-column-header em::selection, .optanon-cookie-policy-cookies-used em::selection, th.optanon-cookie-policy-left em::selection, th.optanon-cookie-policy-right em::selection, .ts-heading-5 u::selection, .optanon-cookie-policy-subgroup-table-column-header u::selection, .optanon-cookie-policy-cookies-used u::selection, th.optanon-cookie-policy-left u::selection, th.optanon-cookie-policy-right u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}

/* #Button
================================================== */
.ts-button-text {
  color: #FFFFFF;
  font-weight: 600;
}

/* #Labels
================================================== */
.ts-label {
  color: #5C0055;
  font-weight: 600;
}

/*
    ===============================
    6. COMPONENTS
    ===============================
*/
.c-accordion__title {
  font-size: 2.25em;
  font-weight: 700;
  line-height: 1.3em;
  border-top: 6px solid #5C0055;
  cursor: pointer;
  padding: 18px 58px 18px 0;
  position: relative;
}
@media screen and (max-width: 1055px) {
  .c-accordion__title {
    font-size: 2em;
  }
}
@media screen and (max-width: 767px) {
  .c-accordion__title {
    font-size: 1.625em;
  }
}
.c-accordion__title::selection {
  background: #FFCB00;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .c-accordion__title {
    padding-right: 52px;
  }
}
.c-content__main .c-accordion__title {
  margin-bottom: 0;
}
.c-accordion__title__icon {
  background: url("../images/accordion-arrow.png") 0 0;
  background-image: url("../images/accordion-arrow.svg"), none;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 41px 24px;
  display: block;
  height: 24px;
  margin-top: -12px;
  position: absolute;
  right: 0;
  top: 50%;
  transition: transform 0.3s;
  width: 41px;
}
@media screen and (max-width: 1055px) {
  .c-accordion__title__icon {
    background: url("../images/accordion-arrow.png") 0 0;
    background-image: url("../images/accordion-arrow.svg"), none;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 34px 20px;
    width: 34px;
    height: 20px;
  }
}
@media screen and (max-width: 767px) {
  .c-accordion__title__icon {
    background: url("../images/accordion-arrow.png") 0 0;
    background-image: url("../images/accordion-arrow.svg"), none;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 27px 16px;
    width: 27px;
    height: 16px;
  }
}
.c-accordion__title:focus {
  border-color: #FFCB00;
  outline: 0;
}
.c-accordion.is-open .c-accordion__title__icon {
  transform: rotate(180deg);
}
.c-accordion__content {
  display: none;
  padding: 12px 0;
}
.c-accordion__content ul, .c-accordion__content ol {
  margin-bottom: 24px;
  padding-left: 21px;
}
.c-accordion__content ul li, .c-accordion__content ol li {
  font-weight: 600;
  margin-bottom: 12px;
}
.c-accordion__content ul {
  list-style-type: disc;
}
.c-accordion__content ul ul {
  list-style-type: circle;
}
.c-accordion__content ol {
  list-style-type: decimal;
}
.c-accordion__content ol ol {
  list-style-type: lower-alpha;
}
.c-accordion__content h2, .c-accordion__content h3 {
  margin-bottom: 24px;
}
.c-accordion__content h4, .c-accordion__content h5 {
  margin-bottom: 12px;
}
.c-accordion__content p {
  margin-bottom: 24px;
}
.c-accordion__content button {
  margin-bottom: 36px;
}

.c-advice-output {
  margin: 24px 0 36px;
}
.c-advice-output ul, .c-advice-output ol {
  margin-bottom: 24px;
  padding-left: 21px;
}
.c-advice-output ul li, .c-advice-output ol li {
  font-weight: 600;
  margin-bottom: 12px;
}
.c-advice-output ul {
  list-style-type: disc;
}
.c-advice-output ul ul {
  list-style-type: circle;
}
.c-advice-output ol {
  list-style-type: decimal;
}
.c-advice-output ol ol {
  list-style-type: lower-alpha;
}
@media screen and (min-width: 1056px) {
  .c-advice-output {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .c-advice-output:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-advice-output {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-advice-output:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    margin: 0;
  }
  .c-advice-output:last-child {
    margin-right: 0;
  }
}
.c-advice-output p {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output p {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output p {
    font-size: 1em;
  }
}
.c-advice-output p a {
  color: #5C0055;
  text-decoration: underline;
  transition: color 0.3s;
}
.c-advice-output p a::selection {
  background: rgba(255, 203, 0, 0.99);
  color: #000000;
}
.c-advice-output p a:hover, .c-advice-output p a:focus {
  color: #000000;
  text-decoration: underline;
}
.c-advice-output p a.c-tooltip {
  text-decoration: none;
}
.c-advice-output p ul::selection, .c-advice-output p ol::selection, .c-advice-output p li::selection, .c-advice-output p strong::selection, .c-advice-output p em::selection, .c-advice-output p u::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output p::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output p--small {
  font-size: 1em;
}
.c-advice-output p--semi-bold {
  font-weight: 600;
}
.c-advice-output p + .c-accordion {
  margin-top: 24px;
}
.c-advice-output ol, .c-advice-output ul {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output ol, .c-advice-output ul {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output ol, .c-advice-output ul {
    font-size: 1em;
  }
}
.c-advice-output ol a, .c-advice-output ul a {
  color: #5C0055;
  text-decoration: underline;
  transition: color 0.3s;
}
.c-advice-output ol a::selection, .c-advice-output ul a::selection {
  background: rgba(255, 203, 0, 0.99);
  color: #000000;
}
.c-advice-output ol a:hover, .c-advice-output ol a:focus, .c-advice-output ul a:hover, .c-advice-output ul a:focus {
  color: #000000;
  text-decoration: underline;
}
.c-advice-output ol a.c-tooltip, .c-advice-output ul a.c-tooltip {
  text-decoration: none;
}
.c-advice-output ol ul::selection, .c-advice-output ol ol::selection, .c-advice-output ol li::selection, .c-advice-output ol strong::selection, .c-advice-output ol em::selection, .c-advice-output ol u::selection, .c-advice-output ul ul::selection, .c-advice-output ul ol::selection, .c-advice-output ul li::selection, .c-advice-output ul strong::selection, .c-advice-output ul em::selection, .c-advice-output ul u::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output ol::selection, .c-advice-output ul::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output ol--small, .c-advice-output ul--small {
  font-size: 1em;
}
.c-advice-output ol--semi-bold, .c-advice-output ul--semi-bold {
  font-weight: 600;
}
.c-advice-output h1 {
  color: #FFFAE3;
  font-size: 2.625em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output h1 {
    font-size: 2.375em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output h1 {
    font-size: 2em;
  }
}
.c-advice-output h1::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.c-advice-output h1 strong::selection, .c-advice-output h1 em::selection, .c-advice-output h1 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.c-advice-output h2 {
  font-size: 2.25em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output h2 {
    font-size: 2em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output h2 {
    font-size: 1.625em;
  }
}
.c-advice-output h2::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output h3 {
  font-size: 1.75em;
  font-weight: 700;
  line-height: 1.3em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output h3 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output h3 {
    font-size: 1.375em;
  }
}
.c-advice-output h3::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output h3 strong::selection, .c-advice-output h3 em::selection, .c-advice-output h3 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.c-advice-output h4 {
  font-size: 1.375em;
  font-weight: 700;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output h4 {
    font-size: 1.25em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output h4 {
    font-size: 1.125em;
  }
}
.c-advice-output h4::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output h4 strong::selection, .c-advice-output h4 em::selection, .c-advice-output h4 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.c-advice-output h5 {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.5em;
}
@media screen and (max-width: 1055px) {
  .c-advice-output h5 {
    font-size: 1.125em;
  }
}
@media screen and (max-width: 767px) {
  .c-advice-output h5 {
    font-size: 1em;
  }
}
.c-advice-output h5::selection {
  background: #FFCB00;
  color: #000000;
}
.c-advice-output h5 strong::selection, .c-advice-output h5 em::selection, .c-advice-output h5 u::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}
.c-advice-output__non-accordion h2, .c-advice-output__non-accordion h3 {
  margin-bottom: 24px;
}
.c-advice-output__non-accordion h4, .c-advice-output__non-accordion h5 {
  margin-bottom: 12px;
}
.c-advice-output__non-accordion p {
  margin-bottom: 24px;
}
.c-advice-output > p, .c-advice-output > ul, .c-advice-output > ol {
  margin-bottom: 24px;
}
.c-advice-output__border-top {
  border-top: 6px solid #5C0055;
  padding-top: 48px;
}

.c-boxout {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  background-color: #FCFAF0;
  border: 3px solid #FFCB00;
  margin-bottom: 36px;
  padding: 18px;
}
.c-boxout ul, .c-boxout ol {
  margin-bottom: 24px;
  padding-left: 21px;
}
.c-boxout ul li, .c-boxout ol li {
  font-weight: 600;
  margin-bottom: 12px;
}
.c-boxout ul {
  list-style-type: disc;
}
.c-boxout ul ul {
  list-style-type: circle;
}
.c-boxout ol {
  list-style-type: decimal;
}
.c-boxout ol ol {
  list-style-type: lower-alpha;
}
.c-boxout:last-child {
  margin-right: 0;
}
.c-boxout__heading, .c-content__main .c-boxout__heading {
  margin-bottom: 12px;
}
.c-boxout__text, .c-boxout p {
  margin-bottom: 24px;
}
.c-boxout__list {
  list-style-type: disc;
  padding-left: 21px;
}
.c-boxout__list__item {
  margin-bottom: 6px;
}
.c-boxout ul:last-child, .c-boxout ol:last-child, .c-boxout p:last-child {
  margin-bottom: 0;
}
.c-boxout--small {
  width: auto;
}
.c-boxout--last {
  margin-bottom: 0;
}

.c-brand-block {
  background-color: #FFFAE3;
  border-bottom: 1px solid #DECCDD;
  margin-bottom: 36px;
  padding: 36px 0;
}
.c-brand-block__container {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 48px;
}
.c-brand-block__container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-brand-block__container {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .c-brand-block__container {
    padding: 0 12px;
  }
}
.c-brand-block__text {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 65.9574468085%;
}
.c-brand-block__text:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-brand-block__text {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 100%;
  }
  .c-brand-block__text:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-brand-block__text {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    margin-bottom: 36px;
  }
  .c-brand-block__text:last-child {
    margin-right: 0;
  }
}
.c-brand-block__text--extra {
  display: none;
}
@media screen and (min-width: 1056px) {
  .c-brand-block__logos {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
    margin-left: 68.085106383%;
    margin-left: 0;
  }
  .c-brand-block__logos:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-brand-block__logos {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 100%;
    margin-left: 0%;
  }
  .c-brand-block__logos:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-brand-block__logos {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    margin-left: 0%;
  }
  .c-brand-block__logos:last-child {
    margin-right: 0;
  }
}
.c-brand-block__logos #logo {
  max-width: 180px;
  width: 36%;
}
.c-brand-block__logos svg {
  margin-left: 4%;
  max-width: 90px;
  vertical-align: middle;
  width: 22%;
}
.c-brand-block__logos svg:first-child {
  margin-left: 0;
}
.c-brand-block--narrow {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 48px;
  background-color: #FFFFFF;
  border-bottom: 0;
  margin-bottom: 0;
}
.c-brand-block--narrow::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-brand-block--narrow {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .c-brand-block--narrow {
    padding: 0 12px;
  }
}
.c-brand-block--narrow .c-brand-block__container {
  border-top: 1px solid #DECCDD;
  padding: 36px 0;
}
.c-brand-block--narrow .c-brand-block__text--extra {
  margin-top: 12px;
  display: inline-block;
}

.c-button {
  background-color: #333333;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 48px;
  padding: 8px 18px 10px;
  text-align: center;
  transition: color 0.3s;
}
@media screen and (max-width: 1055px) {
  .c-button {
    padding: 8px 18px 13px;
  }
}
@media screen and (max-width: 767px) {
  .c-button {
    padding: 10px 18px 14px;
  }
}
.c-button:hover, .c-button:focus {
  color: #FFCB00;
  outline: 0;
}
.c-button--full-width {
  display: block;
}

.c-content {
  padding-bottom: 36px;
}
.c-content__container {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 48px;
}
.c-content__container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-content__container {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .c-content__container {
    padding: 0 12px;
  }
}
.c-content__header {
  padding-top: 36px;
}
@media screen and (min-width: 1056px) {
  .c-content__header {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .c-content__header:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-content__header {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-content__header:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-content__header {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-content__header:last-child {
    margin-right: 0;
  }
}
.c-content__header h1, .c-content__header h2, .c-content__header h3 {
  margin-bottom: 24px;
}
.c-content__main ul, .c-content__main ol {
  margin-bottom: 24px;
  padding-left: 21px;
}
.c-content__main ul li, .c-content__main ol li {
  font-weight: 600;
  margin-bottom: 12px;
}
.c-content__main ul {
  list-style-type: disc;
}
.c-content__main ul ul {
  list-style-type: circle;
}
.c-content__main ol {
  list-style-type: decimal;
}
.c-content__main ol ol {
  list-style-type: lower-alpha;
}
@media screen and (min-width: 1056px) {
  .c-content__main {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .c-content__main:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-content__main {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-content__main:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-content__main {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-content__main:last-child {
    margin-right: 0;
  }
}
.c-content__main h1, .c-content__main h2, .c-content__main h3 {
  margin-bottom: 24px;
}
.c-content__main h4, .c-content__main h5 {
  margin-bottom: 12px;
}
.c-content__main ul ul, .c-content__main ul ol, .c-content__main ol ul, .c-content__main ol ol {
  font-size: 1em;
}
.c-content__main p {
  margin-bottom: 24px;
}
.c-content__main p:last-child {
  margin-bottom: 0;
}
.c-content__main p.ts-body--large-margin-bottom {
  margin-bottom: 36px;
}
.c-content__main:last-child {
  margin-bottom: 0;
}
.c-content__main--padding-top {
  padding-top: 36px;
}
.c-content__aside {
  border-left: 1px solid #DECCDD;
  margin-bottom: 36px;
  padding-left: 18px;
}
@media screen and (min-width: 1056px) {
  .c-content__aside {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .c-content__aside:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-content__aside {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 30.4347826087%;
  }
  .c-content__aside:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-content__aside {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    border-left: 0;
    margin-top: 36px;
    padding-left: 0;
  }
  .c-content__aside:last-child {
    margin-right: 0;
  }
  .c-content__aside .u-clear {
    display: none;
  }
}
.c-content__aside__heading {
  margin-bottom: 12px;
}
.c-content__aside p {
  margin-bottom: 12px;
}
.c-content__aside p:last-child {
  margin-bottom: 0;
}
.c-content__aside--no-border {
  border-left: 0;
  padding-left: 0;
}
.c-content__full-width {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
  border-top: 1px solid #DECCDD;
  margin-top: 36px;
  padding-top: 36px;
}
.c-content__full-width:last-child {
  margin-right: 0;
}

.c-country-dropdown {
  box-shadow: unset;
}
.c-country-dropdown.ui-autocomplete-input {
  border: 3px solid #D9D9D9;
  box-shadow: unset;
  padding: 12px;
  color: #5C0055;
  font-weight: 600;
}
.c-country-dropdown.ui-autocomplete-input.is-selected {
  background-color: #FFCB00;
  border-color: #5C0055;
  color: #5C0055;
}

.ui-autocomplete.ui-front {
  border: 3px solid #D9D9D9;
  border-top: 0;
  position: relative;
}

.ui-autocomplete.ui-menu .ui-menu-item {
  border-top: 3px solid #D9D9D9;
  color: #5C0055;
  font-size: 16px;
  padding: 10px;
}
.ui-autocomplete.ui-menu .ui-menu-item:hover, .ui-autocomplete.ui-menu .ui-menu-item:focus {
  background-color: #FFCB00;
}
.ui-autocomplete.ui-menu .ui-autocomplete-focus {
  background-color: #FFCB00;
}

.c-footer {
  background: #FFFAE3;
  border-top: 3px solid #5C0055;
  padding: 12px 0 48px;
}
@media screen and (max-width: 767px) {
  .c-footer {
    padding-top: 24px;
  }
}
.c-footer__container {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 48px;
}
.c-footer__container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-footer__container {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .c-footer__container {
    padding: 0 12px;
  }
}
.c-footer__inner {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 100%;
}
.c-footer__inner:last-child {
  margin-right: 0;
}
.c-footer__left {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 40.4255319149%;
}
.c-footer__left:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-footer__left {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 47.8260869565%;
  }
  .c-footer__left:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-footer__left {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    margin-bottom: 12px;
  }
  .c-footer__left:last-child {
    margin-right: 0;
  }
}
.c-footer__right {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 57.4468085106%;
  text-align: right;
}
.c-footer__right:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-footer__right {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 47.8260869565%;
  }
  .c-footer__right:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-footer__right {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
    text-align: left;
  }
  .c-footer__right:last-child {
    margin-right: 0;
  }
}
.c-footer__links li {
  display: inline-block;
  margin-right: 12px;
  vertical-align: top;
}
@media screen and (max-width: 767px) {
  .c-footer__links li {
    display: block;
  }
}

.c-form-navigation {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #DECCDD;
  margin-bottom: 24px;
  padding: 36px 0 24px;
}
.c-form-navigation::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-form-navigation {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 100%;
  }
  .c-form-navigation:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-form-navigation {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-form-navigation:last-child {
    margin-right: 0;
  }
}
.c-form-navigation__heading {
  margin-bottom: 24px;
}
.c-form-navigation__button {
  margin: 0 12px 12px 0;
}

.c-glossary {
  border-top: 1px solid #DECCDD;
  padding-top: 36px;
  margin: 36px 0;
}
.c-glossary__title {
  margin-bottom: 24px;
}
.c-glossary__term {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 65.9574468085%;
  margin-bottom: 12px;
}
.c-glossary__term:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-glossary__term {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-glossary__term:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-glossary__term {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-glossary__term:last-child {
    margin-right: 0;
  }
}
.c-glossary__term__title {
  cursor: pointer;
}
.c-glossary__term__title:focus {
  color: black;
}
.c-glossary__term__icon {
  display: inline-block;
  fill: #5C0055;
  height: 14px;
  margin: 8px 12px 0 0;
  transition: transform 0.3s;
  vertical-align: top;
  width: 14px;
}
.c-glossary__term__text {
  border-left: 1px solid #DECCDD;
  display: none;
  margin: 12px 0 24px 6px;
  padding-left: 19px;
}
.c-glossary__term.is-open .c-glossary__term__icon {
  transform: rotate(90deg);
}

.c-header {
  background-color: #5C0055;
  padding: 24px 0;
  width: 100%;
}
.c-header__container {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 48px;
}
.c-header__container::after {
  clear: both;
  content: "";
  display: block;
}
@media screen and (max-width: 1055px) {
  .c-header__container {
    padding: 0 24px;
  }
}
@media screen and (max-width: 767px) {
  .c-header__container {
    padding: 0 12px;
  }
}
@media screen and (min-width: 1056px) {
  .c-header__inner {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 100%;
  }
  .c-header__inner:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1055px) {
  .c-header__inner {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 100%;
  }
  .c-header__inner:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-header__inner {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-header__inner:last-child {
    margin-right: 0;
  }
}

.c-page-list ol {
  counter-reset: item;
}
.c-page-list ol li {
  list-style: none;
}
.c-page-list ol li:before {
  content: counters(item, ".") ". ";
  counter-increment: item;
  display: inline-block;
  margin-left: -15px;
  user-select: none;
  width: 30px;
}
.c-page-list ol li li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  display: inline-block;
  margin: 0 10px 0 -62px;
  user-select: none;
  width: 52px;
}
.c-page-list ol ol {
  padding-left: 78px;
  margin-bottom: 0;
}
.c-page-list ol ol:first-child {
  margin-top: 12px;
}
.c-page-list ol ol ol {
  padding-left: 21px;
}
.c-page-list ol li li li:before {
  display: none;
}
.c-page-list ol li li li {
  list-style: lower-alpha;
}
.c-page-list ol li li li:first-child {
  margin-top: 12px;
}

.c-question {
  max-width: 1420px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 36px;
}
.c-question::after {
  clear: both;
  content: "";
  display: block;
}
.c-question__container {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 65.9574468085%;
}
.c-question__container:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-question__container {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-question__container:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-question__container {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-question__container:last-child {
    margin-right: 0;
  }
}
.c-question__question {
  margin-bottom: 24px;
}
.c-question__option {
  display: table;
  margin-bottom: 12px;
  min-height: 54px;
}
.c-question__option__input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.c-question__option__input:checked + label, .c-question__option__input:hover + label {
  background-color: #FFCB00;
}
.c-question__option__input:checked + label:before, .c-question__option__input:hover + label:before {
  background-color: #5C0055;
}
.c-question__option__input:focus + label {
  background-color: #FFCB00;
}
.c-question__option__label {
  border: 3px solid #5C0055;
  cursor: pointer;
  display: table-cell;
  padding: 8px 15px 10px 48px;
  position: relative;
  transition: background-color 0.3s;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .c-question__option__label {
    padding: 8px 15px 10px 46px;
  }
}
.c-question__option__label:before {
  border: 3px solid #5C0055;
  border-radius: 50%;
  content: "";
  height: 18px;
  left: 15px;
  margin-top: -9px;
  position: absolute;
  top: 50%;
  transition: background-color 0.3s;
  width: 18px;
}
@media screen and (max-width: 767px) {
  .c-question__option__label:before {
    height: 16px;
    margin-top: -8px;
    width: 16px;
  }
}
.c-question__option:last-child {
  margin-bottom: 0;
}
.c-question__alert {
  margin-bottom: 24px;
}

.c-send-email {
  float: left;
  display: block;
  margin-right: 2.1276595745%;
  width: 65.9574468085%;
  background: #FFCB00;
  border: 3px solid #5C0055;
  padding: 18px;
  margin-bottom: 36px;
  max-width: 654px;
}
.c-send-email:last-child {
  margin-right: 0;
}
@media screen and (max-width: 1055px) {
  .c-send-email {
    float: left;
    display: block;
    margin-right: 4.347826087%;
    width: 65.2173913043%;
  }
  .c-send-email:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-send-email {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 100%;
  }
  .c-send-email:last-child {
    margin-right: 0;
  }
}
.c-send-email__text {
  margin-bottom: 24px;
}
.c-send-email__form {
  margin-bottom: 6px;
}
.c-send-email__input,
.c-send-email input[type=email] {
  border: 1px solid #D9D9D9;
  height: 48px;
  padding-left: 12px;
  width: calc(100% - 84px);
}
@media screen and (max-width: 767px) {
  .c-send-email__input,
.c-send-email input[type=email] {
    width: calc(100% - 75px);
  }
}
.c-send-email__button {
  margin-left: -4px;
}

.c-tooltip {
  border-bottom: 1px dotted #000000;
  color: #5C0055;
  cursor: help;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}
.c-tooltip::selection {
  background: #FFCB00;
  color: #000000;
}
.c-tooltip:after, .c-tooltip:before {
  border: solid transparent;
  border-bottom-color: #FFCB00;
  border-width: 16px;
  bottom: -22px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -16px;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  transition-timing-function: linear;
  pointer-events: none;
  width: 0;
  z-index: 20;
}
.c-tooltip.tooltip-open {
  color: #000000;
}
.c-tooltip.tooltip-open:after {
  opacity: 1;
}
.c-tooltip__tooltip {
  background-color: #FFCB00;
  display: none;
  max-width: 540px;
  padding: 20px;
  position: absolute;
  z-index: 10;
  /* &:after, &:before {
     border-width   : 16px;
     top            : -32px;
     left           : 50%;
     border         : solid transparent;
     content        : " ";
     height         : 0;
     opacity        : 1;
     width          : 0;
     position       : absolute;
     pointer-events : none;
     z-index        : 20;
   }

   &:after {
     border-color        : rgba(136, 183, 213, 0);
     border-bottom-color : $yellow;
     border-width        : 16px;
     margin-left         : -16px;
     z-index             : 21;
   }

   &:before {
     border-color        : rgba(255, 255, 255, 0);
     border-bottom-color : red;
     border-width        : 20px;
     margin-left         : -20px;
     top                 : -40px;
   }*/
}
.c-tooltip__tooltip ul, .c-tooltip__tooltip ol {
  margin-bottom: 24px;
  padding-left: 21px;
}
.c-tooltip__tooltip ul li, .c-tooltip__tooltip ol li {
  font-weight: 600;
  margin-bottom: 12px;
}
.c-tooltip__tooltip ul {
  list-style-type: disc;
}
.c-tooltip__tooltip ul ul {
  list-style-type: circle;
}
.c-tooltip__tooltip ol {
  list-style-type: decimal;
}
.c-tooltip__tooltip ol ol {
  list-style-type: lower-alpha;
}
@media screen and (max-width: 767px) {
  .c-tooltip__tooltip {
    display: block;
    left: 0 !important;
    margin: 0 auto;
    right: 0;
    width: calc(100% - 24px);
  }
}
.c-tooltip__tooltip p,
.c-tooltip__tooltip ul li,
.c-tooltip__tooltip ol li {
  font-size: 1em;
  font-weight: 400;
}
.c-tooltip__tooltip p ul, .c-tooltip__tooltip p ol,
.c-tooltip__tooltip ul li ul,
.c-tooltip__tooltip ul li ol,
.c-tooltip__tooltip ol li ul,
.c-tooltip__tooltip ol li ol {
  font-size: 1em;
}
.c-tooltip__tooltip p:last-child, .c-tooltip__tooltip ul:last-child, .c-tooltip__tooltip ol:last-child {
  margin-bottom: 0;
}
.c-tooltip__tooltip p::selection, .c-tooltip__tooltip ul::selection, .c-tooltip__tooltip ol::selection {
  background: rgba(255, 255, 255, 0.99);
  color: #000000;
}

/*
    ===============================
    7. TRUMPS
    ===============================
*/
/* #Generic colour classes
================================================== */
.u-black {
  color: #000000;
}

.u-black-bg {
  background: #000000;
}

.u-white {
  color: #FFFFFF;
}

.u-white-bg {
  background: #FFFFFF;
}

.u-grey {
  color: #333333;
}

.u-grey-bg {
  background: #333333;
}

.u-yellow {
  color: #FFCB00;
}

.u-yellow-bg {
  background: #FFCB00;
}

.u-pale-yellow {
  color: #FFFAE3;
}

.u-pale-yellow-bg {
  background: #FFFAE3;
}

.u-purple {
  color: #5C0055;
}

.u-purple-bg {
  background: #5C0055;
}

.u-pale-purple {
  color: #DECCDD;
}

.u-pale-purple-bg {
  background: #DECCDD;
}

.u-teal {
  color: #006374;
}

.u-teal-bg {
  background: #006374;
}

.u-paler-yellow {
  color: #FCFAF0;
}

.u-paler-yellow-bg {
  background: #FCFAF0;
}

/* #Positioning
================================================== */
.u-clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.u-left {
  float: left;
}

.u-right {
  float: right;
}

.u-inline-block {
  display: inline-block;
  vertical-align: top;
}
.u-inline-block + .u-inline-block {
  margin-left: -4px;
}

.u-text-left {
  text-align: left;
}

.u-text-center {
  text-align: center;
}

.u-text-right {
  text-align: right;
}

.u-row {
  display: block;
}
.u-row::after {
  clear: both;
  content: "";
  display: block;
}

/* #Miscellaneous
================================================== */
.u-hidden {
  display: none;
}

.u-anchor {
  display: block;
  margin-top: -140px;
  position: absolute;
  visibility: hidden;
}

.u-relative {
  position: relative;
}

.u-font-zero {
  font-size: 0;
}

.u-width-auto {
  width: auto;
}

/*
    ===============================
    RESPONSIVE STYLES
    ===============================
*/
.is-mobile,
.is-tablet {
  display: none;
}

.ui-autocomplete {
  padding: 0;
  list-style: none;
  background-color: #FFFFFF;
  width: 218px;
  border: 1px solid #B0BECA;
  max-height: 350px;
  overflow-x: hidden;
}

.ui-autocomplete .ui-menu-item {
  border-top: 1px solid #B0BECA;
  display: block;
  padding: 4px 6px;
  color: #353D44;
  cursor: pointer;
}

.ui-autocomplete .ui-menu-item:first-child {
  border-top: none;
}

.ui-autocomplete .ui-menu-item.ui-state-focus {
  background-color: #D5E5F4;
  color: #161A1C;
}

/*! jQuery UI - v1.11.0 - 2014-08-12
* http://jqueryui.com
* Includes: core.css, autocomplete.css, menu.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-clearfix {
  min-height: 0; /* support: IE7 */
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0);
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none;
}

.ui-menu .ui-menu {
  position: absolute;
}

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px 0.4em;
  cursor: pointer;
  min-height: 0; /* support: IE7 */
  /* support: IE10, see #8844 */
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0;
}

/* icon support */
.ui-menu-icons {
  position: relative;
}

.ui-menu-icons .ui-menu-item {
  padding-left: 2em;
}

/* left-aligned */
.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.2em;
  margin: auto 0;
}

/* right-aligned */
.ui-menu .ui-menu-icon {
  left: auto;
  right: 0;
}

/* Fonts
================================================== */
#optanon, #optanon *, #optanon div, #optanon span, #optanon ul, #optanon li, #optanon a, #optanon p, .optanon-alert-box-wrapper * {
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

/* Cookie Bar
================================================== */
.optanon-alert-box-wrapper {
  background-color: #5C0055 !important;
  left: 0;
  min-height: 90px !important;
}
.optanon-alert-box-wrapper .optanon-alert-box-body {
  margin-right: 370px !important;
  position: absolute;
  top: 50%;
  margin-top: -23px;
}
@media only screen and (max-width: 1295px) {
  .optanon-alert-box-wrapper .optanon-alert-box-body {
    max-width: 50%;
  }
}
@media only screen and (max-width: 1070px) {
  .optanon-alert-box-wrapper .optanon-alert-box-body {
    position: relative;
    margin-top: 0;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .optanon-alert-box-wrapper .optanon-alert-box-body {
    box-sizing: border-box;
    margin: 0 !important;
    max-width: 100%;
    padding: 16px;
    width: 85%;
  }
}
.optanon-alert-box-wrapper .optanon-alert-box-body p a {
  margin-left: 10px;
  text-decoration: underline !important;
}
.optanon-alert-box-wrapper .optanon-alert-box-button,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle {
  background-color: #006374 !important;
  border: 1px solid #006374 !important;
  float: right !important;
  line-height: 20px;
  min-height: 20px;
}
.optanon-alert-box-wrapper .optanon-alert-box-button:hover,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle:hover {
  background-color: #000000 !important;
  border: 1px solid #000000 !important;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-allow-all,
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-alert-box-close,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-allow-all,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-alert-box-close {
  display: inline-block;
  vertical-align: middle;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-allow-all:before,
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-alert-box-close:before,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-allow-all:before,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-alert-box-close:before {
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-allow-all:hover, .optanon-alert-box-wrapper .optanon-alert-box-button .optanon-allow-all:focus,
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-alert-box-close:hover,
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-alert-box-close:focus,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-allow-all:hover,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-allow-all:focus,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-alert-box-close:hover,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-alert-box-close:focus {
  background-color: #000000 !important;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-toggle-display,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-toggle-display {
  display: inline-block;
  vertical-align: middle;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-toggle-display:before,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-toggle-display:before {
  display: none;
}
.optanon-alert-box-wrapper .optanon-alert-box-button .optanon-toggle-display:hover, .optanon-alert-box-wrapper .optanon-alert-box-button .optanon-toggle-display:focus,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-toggle-display:hover,
.optanon-alert-box-wrapper .optanon-alert-box-button-middle .optanon-toggle-display:focus {
  border-bottom: 0 !important;
}
.optanon-alert-box-wrapper .optanon-button-close {
  display: none;
}
@media screen and (max-width: 767px) {
  .optanon-alert-box-wrapper .optanon-button-close {
    display: none !important;
  }
}
.optanon-alert-box-wrapper .optanon-alert-box-button-container {
  float: left !important;
  padding-left: 10px;
}
@media screen and (max-width: 1055px) {
  .optanon-alert-box-wrapper .optanon-alert-box-button-container {
    display: block;
    padding-right: 0 !important;
    position: relative !important;
    text-align: center;
    margin: 5px;
    margin-top: 10px !important;
    padding-left: 42px;
    top: auto !important;
    right: auto !important;
  }
}
@media screen and (max-width: 767px) {
  .optanon-alert-box-wrapper .optanon-alert-box-button-container {
    padding-left: 10px;
  }
}
@media screen and (max-width: 767px) {
  .optanon-alert-box-wrapper .optanon-alert-box-button.optanon-button-allow {
    margin: 0 10px;
  }
}
@media screen and (max-width: 767px) {
  .optanon-alert-box-wrapper .optanon-alert-box-corner-close {
    margin-top: -75px !important;
  }
}
.optanon-alert-box-wrapper .optanon-button-more {
  display: block !important;
}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle {
  padding: 10px 20px !important;
}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a, .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button {
  border-bottom: 0 !important;
}
.optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle a:focus, .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button:focus {
  outline: 0;
}

/* Cookie Popup
================================================== */
#optanon #optanon-popup-bg {
  display: none !important;
}

#optanon #optanon-popup-wrapper {
  box-shadow: 0 0 40px 10px rgb(170, 170, 170) !important;
  /* OneTrust Logo removal (probably not advised) */
}
@media screen and (max-width: 767px) {
  #optanon #optanon-popup-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container {
    margin: 0 !important;
  }
  #optanon #optanon-popup-wrapper .optanon-alert-box-bg .optanon-alert-box-button-container:after {
    clear: both;
    content: "";
    display: block;
  }
}
#optanon #optanon-popup-wrapper #optanon-menu li {
  background-position: 10px 12px !important;
}
#optanon #optanon-popup-wrapper .optanon-alert-box-wrapper {
  background-color: #000000 !important;
}
#optanon #optanon-popup-wrapper #optanon-show-settings-popup {
  display: none !important;
}
#optanon #optanon-popup-wrapper .optanon-alert-box-button.optanon-button-more {
  display: block !important;
  margin-right: 20px !important;
}
@media screen and (max-width: 767px) {
  #optanon #optanon-popup-wrapper .optanon-alert-box-button.optanon-button-more {
    margin-left: 17px !important;
  }
}
#optanon #optanon-popup-wrapper #optanon-popup-top {
  background-color: #DECCDD;
}
#optanon #optanon-popup-wrapper #optanon-popup-body-left-shading {
  display: none !important;
}
#optanon #optanon-popup-wrapper #optanon-branding-top-logo {
  display: none !important;
}
#optanon #optanon-popup-wrapper #optanon-popup-bottom {
  background-color: #DECCDD;
}
#optanon #optanon-popup-wrapper #optanon-popup-bottom-logo {
  display: none !important;
}
#optanon #optanon-popup-wrapper div.optanon-white-button-middle {
  background-color: #006374 !important;
}
#optanon #optanon-popup-wrapper div.optanon-white-button-middle a {
  color: #FFFFFF !important;
}
#optanon #optanon-popup-wrapper div.optanon-white-button-middle:hover, #optanon #optanon-popup-wrapper div.optanon-white-button-middle:focus {
  background-color: #000000 !important;
}
#optanon #optanon-popup-wrapper div.optanon-white-button-middle:hover a, #optanon #optanon-popup-wrapper div.optanon-white-button-middle:focus a {
  color: #FFFFFF !important;
}
#optanon #optanon-popup-wrapper .optanon-status-on {
  color: #006374 !important;
}
#optanon #optanon-popup-wrapper fieldset input:checked + label {
  background-color: #006374;
}

/* Cookie Policy Styles
================================================== */
.content-from-editor .optanon-show-settings-popup-wrapper {
  height: auto;
}
.content-from-editor .optanon-show-settings-popup-wrapper .optanon-show-settings-middle a.optanon-show-settings {
  display: inline;
}
.content-from-editor p.optanon-cookie-policy-group-name {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #000000;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}
.content-from-editor .optanon-show-settings-popup-wrapper:after,
.content-from-editor .optanon-show-settings-button:after,
.content-from-editor .optanon-show-settings-right:after {
  clear: both;
  content: "";
  display: block;
}
.content-from-editor #optanon-show-settings-popup {
  display: none !important;
}

/* Menu Icon Changes
================================================== */
/* Cookie Policy Page
================================================== */
#optanon-cookie-policy {
  margin-top: 30px;
}

.optanon-cookie-policy-subgroup-table-column-header {
  font-size: 1em;
}

.optanon-cookie-policy-left {
  height: auto !important;
}

.optanon-cookie-policy-subgroup-table {
  background-color: #FCFAF0;
  border: 3px solid #FFCB00;
}

th.optanon-cookie-policy-left, th.optanon-cookie-policy-right, td.optanon-cookie-policy-left, td.optanon-cookie-policy-right {
  padding: 1.5rem !important;
}