$active-color : $teal;
$inactive-color : $black;

/* Fonts
================================================== */
#optanon, #optanon *, #optanon div, #optanon span, #optanon ul, #optanon li, #optanon a, #optanon p, .optanon-alert-box-wrapper * {
  font-family : "Roboto", Arial, Helvetica, sans-serif !important;
}
/* Cookie Bar
================================================== */

.optanon-alert-box-wrapper {
  background-color : $purple !important;
  left             : 0;
  min-height       : 90px !important;

  // Bar text
  .optanon-alert-box-body {
	margin-right : 370px !important;
	position     : absolute;
	top          : 50%;
	margin-top   : -23px;

	@media only screen and (max-width : 1295px) {
	  max-width : 50%;
	}

	@media only screen and (max-width : 1070px) {
	  position   : relative;
	  margin-top : 0;
	  max-width  : 100%;

	}

	@include media($small) {
	  box-sizing : border-box;
	  margin     : 0 !important;
	  max-width  : 100%;
	  padding    : 16px;
	  width      : 85%;
	}

	p {
	  a {
		margin-left     : 10px;
		text-decoration : underline !important;
	  }
	}
  }

  .optanon-alert-box-button,
  .optanon-alert-box-button-middle {
	background-color : $teal !important;
	border           : 1px solid $teal !important;
	float            : right !important;
	line-height      : 20px;
	min-height       : 20px;

	&:hover {
	  background-color : $black !important;
	  border           : 1px solid $black !important;

	}

	// Coloured buttons
	.optanon-allow-all,
	.optanon-alert-box-close {
	  display        : inline-block;
	  vertical-align : middle;

	  &:before {
		display : none;
	  }

	  &:hover, &:focus {
		background-color : $black !important;
	  }
	}

	// Cookie settings button
	.optanon-toggle-display {
	  //padding-top    : $baseline-spacing;
	  display        : inline-block;
	  vertical-align : middle;

	  &:before {
		display : none;
	  }

	  &:hover, &:focus {
		border-bottom : 0 !important;
	  }
	}

  }

  .optanon-button-close {
	//display : block !important;
	display : none;
	@include media($small) {
	  display : none !important;
	}
  }

  // Button Ordering
  .optanon-alert-box-button-container {
	//display         : flex;
	//justify-content : flex-start;
	//align-items     : stretch;
	//flex-direction  : row;
	//flex-wrap       : wrap;
	float        : left !important;
	//margin-top: 0 !important;
	padding-left : 10px;

	@include media($medium) {
	  display       : block;
	  padding-right : 0 !important;
	  position      : relative !important;
	  text-align    : center;
	  margin        : 5px;
	  margin-top    : 10px !important;
	  padding-left  : 42px;
	  top           : auto !important;
	  right         : auto !important;
	}

	@include media($small) {
	  padding-left : 10px;
	}
  }

  .optanon-alert-box-button.optanon-button-allow {
	@include media($small) {
	  margin : 0 10px;
	}
  }

  // Hide the close button
  .optanon-alert-box-corner-close {
	//display : none !important;
	//margin-top: -45px !important;

	@include media($small) {
	  margin-top : -75px !important;
	}
  }

  .optanon-button-more {
	display : block !important;

	.optanon-alert-box-button-middle {
	  padding : 10px 20px !important;

	  a, button {
		border-bottom : 0 !important;
		&:focus {
		  outline : 0;
		}
	  }

	}
  }

}
/* Cookie Popup
================================================== */
#optanon #optanon-popup-bg {
  display : none !important;
}
#optanon #optanon-popup-wrapper {
  box-shadow : 0 0 40px 10px rgba(170, 170, 170, 1) !important;

  .optanon-alert-box-bg .optanon-alert-box-button-container {
	@include media($small) {
	  margin : 0 !important;

	  &:after {
		clear   : both;
		content : "";
		display : block;
	  }
	}
  }
  #optanon-menu li {
	background-position : 10px 12px !important;
  }
  .optanon-alert-box-wrapper {
	background-color : $black !important
  }
  #optanon-show-settings-popup {
	display : none !important;
  }
  .optanon-alert-box-button.optanon-button-more {
	display      : block !important;
	margin-right : 20px !important;

	@include media($small) {
	  margin-left : 17px !important;
	}
  }

  #optanon-popup-top {
	background-color : $pale-purple;
  }

  #optanon-popup-body-left-shading {
	display : none !important;
  }
  #optanon-branding-top-logo {
	display : none !important;
  }

  #optanon-popup-bottom {
	background-color : $pale-purple;
  }

  /* OneTrust Logo removal (probably not advised) */
  #optanon-popup-bottom-logo {
	display : none !important;
  }

  // Buttons
  div.optanon-white-button-middle {
	background-color : $teal !important;

	a {
	  color : $white !important;
	}

	&:hover, &:focus {
	  background-color : $black !important;

	  a {
		color : $white !important;
	  }
	}
  }

  // Toggle Field Text
  .optanon-status-on {
	color : $active-color !important;
  }

  // Toggle Field
  fieldset input:checked + label {
	background-color : $active-color;
  }
}
/* Cookie Policy Styles
================================================== */
.content-from-editor {
  .optanon-show-settings-popup-wrapper {
	height : auto;
	//margin-bottom : $baseline-spacing * 6;

	.optanon-show-settings-middle a.optanon-show-settings {
	  display : inline;
	}
  }

  p.optanon-cookie-policy-group-name {
	@include sans;
	color       : $black;
	font-size   : 32px;
	font-weight : 700;
	line-height : 40px;
  }

  .optanon-show-settings-popup-wrapper:after,
  .optanon-show-settings-button:after,
  .optanon-show-settings-right:after {
	clear   : both;
	content : "";
	display : block;
  }

  #optanon-show-settings-popup {
	display : none !important;
  }
}
/* Menu Icon Changes
================================================== */
#optanon #optanon-menu {

}
/* Cookie Policy Page
================================================== */
#optanon-cookie-policy {
  margin-top : 30px;
}
.optanon-cookie-policy-group-name {
  @extend .ts-heading-3;
}
.optanon-cookie-policy-cookies-used, th.optanon-cookie-policy-left, th.optanon-cookie-policy-right {
  @extend .ts-heading-5;
}
.optanon-cookie-policy-subgroup-table-column-header {
  @extend .ts-heading-5;
  font-size : 1em;
}
.optanon-cookie-policy-group-description, .optanon-cookie-policy-group-cookies-list, .optanon-cookie-policy-subgroup-table, td.optanon-cookie-policy-left, td.optanon-cookie-policy-right, .optanon-cookie-policy-subgroup-name, .optanon-cookie-policy-subgroup-cookies-list li {
  @extend .ts-body--small;
}
.optanon-cookie-policy-left {
  height : auto !important;
}
.optanon-cookie-policy-subgroup-table {
  background-color : $paler-yellow;
  border           : 3px solid $yellow;
}
th.optanon-cookie-policy-left, th.optanon-cookie-policy-right, td.optanon-cookie-policy-left, td.optanon-cookie-policy-right {
  padding : 1.5rem !important;
}
