.c-boxout {
  @include default-list-style;
  @include span-columns(12);
  background-color : $paler-yellow;
  border           : 3px solid $yellow;
  margin-bottom    : 36px;
  padding          : 18px;

  &__heading,
  .c-content__main &__heading {
    margin-bottom : 12px;
  }

  &__text, p {
    margin-bottom : 24px;
  }

  &__list {
    list-style-type : disc;
    padding-left    : 21px;

    &__item {
      margin-bottom : 6px;
    }
  }

  ul, ol, p {
    &:last-child {
      margin-bottom : 0;
    }
  }

  &--small {
    //@include span-columns(8);
    width : auto;

    @include media ($medium) {
      //@include span-columns(4);
    }

    @include media ($small) {
      // @include span-columns(4);
    }
  }

  &--last {
    margin-bottom : 0;
  }
}
