.c-brand-block {
  background-color : $pale-yellow;
  border-bottom    : 1px solid $pale-purple;
  margin-bottom    : 36px;
  padding          : 36px 0;

  &__container {
    @include outer-container();
    @include outer-padding();
  }

  &__text {
    @include span-columns(8);

    @include media($medium) {
      @include span-columns(6);
    }

    @include media($small) {
      @include span-columns(4);
      margin-bottom : 36px;
    }

    &--extra {
      display : none;
    }
  }

  &__logos {
    @include media($large) {
      @include span-columns(4);
      @include shift(8);
      margin-left : 0;

    }

    @include media($medium) {
      @include span-columns(6);
      @include shift(0);

    }

    @include media($small) {
      @include span-columns(4);
      @include shift(0);
    }

    #logo {
      max-width : 180px;
      width     : 36%;
    }

    svg {
      margin-left    : 4%;
      max-width      : 90px;
      vertical-align : middle;
      width          : 22%;

      &:first-child {
        margin-left : 0;
      }
    }
  }

  &--narrow {
    @include outer-container();
    @include outer-padding();
    background-color : $white;
    border-bottom    : 0;
    margin-bottom    : 0;

    .c-brand-block__container {
      border-top : 1px solid $pale-purple;
      padding    : 36px 0;
    }

    .c-brand-block__text--extra {
      margin-top : 12px;
      display    : inline-block;
    }

    //
  }
}
