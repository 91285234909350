.c-header {
  background-color : $purple;
  padding          : 24px 0;
  width            : 100%;

  &__container {
    @include outer-container();
    @include outer-padding();
  }

  &__inner {
    @include media($large) {
      @include span-columns(12);
    }

    @include media($medium) {
      @include span-columns(6);
    }

    @include media($small) {
      @include span-columns(4);
    }
  }
}
