/* #Generic colour classes
================================================== */
@each $name in $colours {
  $i : index($colours, $name);

  .u-#{$name} {
    color : nth($colour_var, $i);
  }

  .u-#{$name}-bg {
    background : nth($colour_var, $i);
  }
}
/* #Positioning
================================================== */
.u-clear {
  clear      : both;
  display    : block;
  overflow   : hidden;
  visibility : hidden;
  width      : 0;
  height     : 0;
}
.u-left {
  float : left;
}
.u-right {
  float : right;
}
.u-inline-block {
  display        : inline-block;
  vertical-align : top;

  & + & {
    margin-left : -4px;
  }
}
.u-text-left {
  text-align : left;
}
.u-text-center {
  text-align : center;
}
.u-text-right {
  text-align : right;
}
.u-row {
  @include row();
}
/* #Miscellaneous
================================================== */
.u-hidden {
  display : none;
}
.u-anchor {
  display    : block;
  margin-top : -140px;
  position   : absolute;
  visibility : hidden;
}
.u-relative {
  position : relative;
}
.u-font-zero {
  font-size : 0;
}
.u-width-auto {
  width : auto;
}
/*
    ===============================
    RESPONSIVE STYLES
    ===============================
*/
.is-mobile,
.is-tablet {
  display : none;
}
