body {
  @include sans;
  background               : $pale-yellow;
  -webkit-font-smoothing   : antialiased;
  -moz-osx-font-smoothing  : grayscale;
  -webkit-text-size-adjust : 100%;
  font-size                : 16px;
}
main {
  background-color : $white;
}
h1 {
  font-size : 2em; //32px
}
h2 {
  font-size : 1.625em; //26px;
}
h3 {
  font-size : 1.375em; //22px;
}
h4 {
  font-size : 1.125em; //18px;
}
h5, h6 {
  font-size : 1em; //16px;
}
p {
  font-size : 1.250em; //16px;
}
a {
  color           : inherit;
  text-decoration : none;
}
a,
p a,
ol li a,
ul li a {
  cursor          : pointer;
  text-decoration : none;

  &:hover, &:focus {
    outline         : 0;
    text-decoration : none;
  }
}
b, strong, body.webkit strong, body.webkit b {
  font-weight : 700;
}
i, em {
  font-style : italic;
}
// Force font on form elements
button, input, textarea, select {
  @include sans;
}
input[type="text"],
input[type="email"] {
  border         : 0;
  box-sizing     : border-box;
  display        : inline-block;
  outline        : 0;
  vertical-align : top;
}
