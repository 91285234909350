.c-form-navigation {
  @include outer-container();
  border-bottom : 1px solid $pale-purple;
  margin-bottom : 24px;
  padding       : 36px 0 24px;

  @include media ($medium) {
    @include span-columns(6);
  }

  @include media ($small) {
    @include span-columns(4);
  }

  &__heading {
    margin-bottom : 24px;
  }

  &__button {
    margin : 0 12px 12px 0;
      }
}
