.c-page-list {
  //Custom list 1 1.2
  ol {
    counter-reset : item;
  }

  ol li {
    list-style : none;
  }

  ol li:before {
    content           : counters(item, ".") ". ";
    counter-increment : item;
    display           : inline-block;
    margin-left       : -15px;
    user-select       : none;
    width             : 30px;
  }

  ol li li:before {
    content           : counters(item, ".") " ";
    counter-increment : item;
    display           : inline-block;
    margin            : 0 10px 0 -62px;
    user-select       : none;
    width             : 52px;
  }

  ol ol {
    padding-left  : 78px;
    margin-bottom : 0;

    &:first-child {
      margin-top : 12px;
    }
  }

  ol ol ol {
    padding-left : 21px;
  }

  ol li li li:before {
    display : none;
  }

  ol li li li {
    list-style : lower-alpha;

    &:first-child {
      margin-top : 12px;
    }
  }
}
