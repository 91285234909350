/* * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/) * http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin         : 0;
  padding        : 0;
  border         : 0;
  font-size      : 100%;
  font           : inherit;
  vertical-align : baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display : block;
}
body {
  line-height : 1;
}
ol, ul {
  list-style : none;
}
blockquote, q {
  quotes : none;
}
blockquote:before, blockquote:after, q:before, q:after {
  content : '';
  content : none;
}
table {
  border-collapse : collapse;
  border-spacing  : 0;
}
/* Prevent `sub` and `sup` affecting `line-height` in all browsers. */
sub,
sup {
  font-size      : 75%;
  line-height    : 0;
  position       : relative;
  vertical-align : baseline;
}
sup {
  top : -0.5em;
}
sub {
  bottom : -0.25em;
}
/* Reset styling on inputs */
input {
  margin : 0;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="search"] {
  appearance            : none;
  -moz-appearance       : none;
  -webkit-appearance    : none;
  border-radius         : 0;
  -moz-border-radius    : 0;
  -webkit-border-radius : 0;
  cursor                : pointer;
}
