.c-button {
  background-color : $grey;
  border           : 0;
  cursor           : pointer;
  display          : inline-block;
  height           : 48px;
  padding          : 8px 18px 10px;
  text-align       : center;
  transition       : color 0.3s;

  @include media($medium) {
    padding : 8px 18px 13px;
  }

  @include media($small) {
    padding : 10px 18px 14px;
  }

  &:hover, &:focus {
    color   : $yellow;
    outline : 0;
  }

  &--full-width {
    display : block;
  }
}
