.c-country-dropdown {
  box-shadow : unset;

  &.ui-autocomplete-input {
    @extend .ts-body;
    border      : 3px solid $light-grey;
    box-shadow  : unset;
    padding     : 12px;
    color       : $purple;
    font-weight : 600;

    &.is-selected {
      background-color : $yellow;
      border-color     : $purple;
      color            : $purple
    }
  }

  //::-webkit-input-placeholder {
  //  color : $teal;
  //}
  //:-moz-placeholder {
  //  color : $teal;
  //}
  //::-moz-placeholder {
  //  color : $teal;
  //}
  //:-ms-input-placeholder {
  //  color : $teal;
  //}
}
// Autocomplete text field
.ui-autocomplete.ui-front {
  border     : 3px solid $light-grey;
  border-top : 0;
  position   : relative;
}
// Autocomplete dropdown list
.ui-autocomplete.ui-menu {
  .ui-menu-item {
    @extend .ts-body;
    border-top : 3px solid $light-grey;
    color      : $purple;
    font-size  : 16px;
    padding    : 10px;

    &:hover, &:focus {
      background-color : $yellow;
      //color            : $white;
    }
  }

  .ui-autocomplete-focus {
    background-color : $yellow;
  }
}
