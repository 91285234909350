/* #General body
================================================== */
@mixin ts-body {
  @include sans;
  color: $grey;
  font-size: 1.250em; //20px
  font-weight: 400;
  line-height: 1.5em;

  @include media($medium) {
    font-size: 1.125em; //18px;
  }
  @include media($small) {
    font-size: 1em; //16px;
  }

  a {
    color: $purple;
    text-decoration: underline;
    transition: color 0.3s;

    &::selection {
      background: rgba($yellow, 0.99);
      color: $black;
    }

    &:hover, &:focus {
      color: $black;
      text-decoration: underline;
    }

    &.c-tooltip {
      text-decoration: none;
    }
  }

  ul, ol, li, strong, em, u {
    &::selection {
      background: $yellow;
      color: $black;
    }
  }

  &::selection {
    background: $yellow;
    color: $black;
  }

  &--small {
    font-size: 1em;
  }

  &--semi-bold {
    font-weight: 600;
  }
}

.ts-body {
  @include ts-body;
}

/* #Headings
================================================== */
@mixin ts-heading-1 {
  color: $pale-yellow;
  font-size: 2.625em; //42px
  font-weight: 700;
  line-height: 1.3em;

  @include media($medium) {
    font-size: 2.375em; //38px;
  }
  @include media($small) {
    font-size: 2em; //32px;
  }

  &::selection {
    background: rgba($white, 0.99);
    color: $black;
  }

  strong, em, u {
    &::selection {
      background: rgba($white, 0.99);
      color: $black;
    }
  }
}

.ts-heading-1 {
  @include ts-heading-1;
}

@mixin ts-heading-2 {
  font-size: 2.25em; //36px
  font-weight: 700;
  line-height: 1.3em;

  @include media($medium) {
    font-size: 2em; //32px;
  }
  @include media($small) {
    font-size: 1.625em; //26px;
  }

  &::selection {
    background: $yellow;
    color: $black;
  }
}

.ts-heading-2 {
  @include ts-heading-2;
}

@mixin ts-heading-3 {
  font-size: 1.750em; //28px
  font-weight: 700;
  line-height: 1.3em;

  @include media($medium) {
    font-size: 1.500em; //24px;
  }
  @include media($small) {
    font-size: 1.375em; //22px;
  }

  &::selection {
    background: $yellow;
    color: $black;
  }

  strong, em, u {
    &::selection {
      background: rgba($white, 0.99);
      color: $black;
    }
  }
}

.ts-heading-3 {
  @include ts-heading-3;
}

@mixin ts-heading-4 {
  font-size: 1.375em; //22px
  font-weight: 700;
  line-height: 1.5em;

  @include media($medium) {
    font-size: 1.250em; //20px;
  }
  @include media($small) {
    font-size: 1.125em; //18px;
  }

  &::selection {
    background: $yellow;
    color: $black;
  }

  strong, em, u {
    &::selection {
      background: rgba($white, 0.99);
      color: $black;
    }
  }
}

.ts-heading-4 {
  @include ts-heading-4;
}

@mixin ts-heading-5 {
  font-size: 1.250em; //20 px
  font-weight: 700;
  line-height: 1.5em;

  @include media($medium) {
    font-size: 1.125em; //18px;
  }
  @include media($small) {
    font-size: 1em; //16px;
  }

  &::selection {
    background: $yellow;
    color: $black;
  }

  strong, em, u {
    &::selection {
      background: rgba($white, 0.99);
      color: $black;
    }
  }
}

.ts-heading-5 {
  @include ts-heading-5;
}

/* #Button
================================================== */
.ts-button-text {
  @extend .ts-body;
  color: $white;
  font-weight: 600;
}

/* #Labels
================================================== */
.ts-label {
  @extend .ts-body;
  color: $purple;
  font-weight: 600;
}

