.c-send-email {
  @include span-columns(8);
  background    : $yellow;
  border        : 3px solid $purple;
  padding       : 18px;
  margin-bottom : 36px;
  max-width     : 654px;

  @include media ($medium) {
    @include span-columns(4);
  }

  @include media ($small) {
    @include span-columns(4);
  }

  &__text {
    margin-bottom : 24px;
  }

  &__form {
    margin-bottom : 6px;
  }

  &__input,
  input[type=email] {
    border       : 1px solid $light-grey;
    height       : 48px;
    padding-left : 12px;
    width        : calc(100% - 84px);

    @include media($small) {
      width : calc(100% - 75px);
    }
  }

  &__button {
    margin-left : -4px;
  }
}
