.c-glossary {
  border-top  : 1px solid $pale-purple;
  padding-top : 36px;
  margin      : 36px 0;

  &__title {
    margin-bottom : 24px;
  }

  &__term {
    @include span-columns(8);
    margin-bottom : 12px;

    @include media ($medium) {
      @include span-columns(4);
    }

    @include media ($small) {
      @include span-columns(4);
    }

    &__title {
      cursor : pointer;

      &:focus {
        color : black
      }
    }

    &__icon {
      display        : inline-block;
      fill           : $purple;
      height         : 14px;
      margin         : 8px 12px 0 0;
      transition     : transform 0.3s;
      vertical-align : top;
      width          : 14px;
    }

    &__text {
      border-left  : 1px solid $pale-purple;
      display      : none;
      margin       : 12px 0 24px 6px;
      padding-left : 19px;
    }

    &.is-open {
      .c-glossary__term__icon {
        transform : rotate(90deg);
      }
    }
  }
}
