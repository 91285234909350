.c-content {
  padding-bottom : 36px;

  &__container {
    @include outer-container();
    @include outer-padding();
  }

  &__header {
    padding-top : 36px;

    @include media($large) {
      @include span-columns(8);
    }

    @include media($medium) {
      @include span-columns(4);
    }

    @include media($small) {
      @include span-columns(4);
    }

    h1, h2, h3 {
      margin-bottom : 24px;
    }
  }

  &__main {
    @include default-list-style;

    @include media($large) {
      @include span-columns(8);
    }

    @include media($medium) {
      @include span-columns(4);
    }

    @include media($small) {
      @include span-columns(4);
    }

    h1, h2, h3 {
      margin-bottom : 24px;
    }

    h4, h5 {
      margin-bottom : 12px;
    }

    ul, ol {
      ul, ol {
        font-size : 1em;
      }
    }

    p {
      margin-bottom : 24px;

      &:last-child {
        margin-bottom : 0;
      }

      &.ts-body--large-margin-bottom {
        margin-bottom : 36px;
      }
    }

    &:last-child {
      margin-bottom : 0;
    }

    &--padding-top {
      padding-top : 36px;
    }
  }

  &__aside {
    border-left   : 1px solid $pale-purple;
    margin-bottom : 36px;
    padding-left  : 18px;

    @include media($large) {
      @include span-columns(4);
    }

    @include media ($medium) {
      @include span-columns(2);
    }

    @include media ($small) {
      @include span-columns(4);
      border-left  : 0;
      margin-top   : 36px;
      padding-left : 0;

      //I know it is bad :(
      .u-clear {
        display : none;
      }
    }

    &__heading {
      margin-bottom : 12px;
    }

    p {
      margin-bottom : 12px;

      &:last-child {
        margin-bottom : 0;
      }
    }

    &--no-border {
      border-left  : 0;
      padding-left : 0;
    }
  }

  &__full-width {
    @include span-columns(12);
    border-top  : 1px solid $pale-purple;
    margin-top  : 36px;
    padding-top : 36px;
  }
}
