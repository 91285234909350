.c-tooltip {
  // The text in the question with a tooltip
  border-bottom   : 1px dotted $black;
  color           : $purple;
  cursor          : help;
  position        : relative;
  text-decoration : none;
  white-space     : nowrap;

  &::selection {
    background : $yellow;
    color      : $black;
  }

  &:after, &:before {
    border                     : solid transparent;
    border-bottom-color        : $yellow;
    border-width               : 16px;
    bottom                     : -22px;
    content                    : " ";
    height                     : 0;
    left                       : 50%;
    margin-left                : -16px;
    opacity                    : 0;
    position                   : absolute;
    transition                 : opacity 0.3s;
    transition-timing-function : linear;
    pointer-events             : none;
    width                      : 0;
    z-index                    : 20;
  }

  &.tooltip-open {
    color : $black;
  }

  // No Border
  &.tooltip-open:after {
    opacity : 1;
  }

  // The actual tooltip
  &__tooltip {
    @include default-list-style;
    background-color : $yellow;
    // border           : 3px solid red;
    display          : none;
    max-width        : 540px;
    padding          : 20px;
    position         : absolute;
    z-index          : 10;

    @include media($small) {
      display : block;
      left    : 0 !important;
      margin  : 0 auto;
      right   : 0;
      width   : calc(100% - 24px);
    }

    p,
    ul li,
    ol li {
      font-size   : 1em;
      font-weight : 400;

      ul, ol {
        font-size : 1em;
      }
    }

    p, ul, ol {
      &:last-child {
        margin-bottom : 0;
      }

      &::selection {
        background : rgba(255, 255, 255, 0.99);
        color      : $black;
      }
    }

    /* &:after, &:before {
       border-width   : 16px;
       top            : -32px;
       left           : 50%;
       border         : solid transparent;
       content        : " ";
       height         : 0;
       opacity        : 1;
       width          : 0;
       position       : absolute;
       pointer-events : none;
       z-index        : 20;
     }

     &:after {
       border-color        : rgba(136, 183, 213, 0);
       border-bottom-color : $yellow;
       border-width        : 16px;
       margin-left         : -16px;
       z-index             : 21;
     }

     &:before {
       border-color        : rgba(255, 255, 255, 0);
       border-bottom-color : red;
       border-width        : 20px;
       margin-left         : -20px;
       top                 : -40px;
     }*/
  }
}
