/*
    ===============================
    COMMON CODE
    ===============================
*/
@mixin checkbox-hide {
  border   : 0;
  clip     : rect(0 0 0 0);
  height   : 1px;
  margin   : -1px;
  overflow : hidden;
  padding  : 0;
  position : absolute;
  width    : 1px;
}
/*
    ===============================
    TYPOGRAPHY
    ===============================
*/

/* Font families */
@mixin sans {
  font-family : 'Open Sans', Arial, Helvetica, sans-serif;
}
/* Body text */
@mixin ts-body {
  @include sans;
  font-size                : 1em;
  font-weight              : normal;
  -webkit-text-size-adjust : none;
}
@mixin default-list-style {
  ul, ol {
    margin-bottom : 24px;
    padding-left  : 21px;

    li {
      font-weight   : 600;
      margin-bottom : 12px;
    }
  }

  ul {
    list-style-type : disc;

    ul {
      list-style-type : circle;
    }
  }

  ol {
    list-style-type : decimal;

    ol {
      list-style-type : lower-alpha;
    }
  }
}
/*
    ===============================
    SVG SPRITE AND FALLBACK
    ===============================
*/
// SVG Image with PNG Fallback
@mixin svg_image($image, $size, $x : 0, $y : 0) {
  background          : url('../images/'+($image)+'.png') $x $y; // IE7, IE8
  background-image    : url('../images/'+($image)+'.svg'), none; // IE9+, FF, Chrome, Safari
  background-position : $x $y;
  background-repeat   : no-repeat;
  background-size     : $size; //Size of PNG
}
/*
    ===============================
    GRID OUTER PADDING
    ===============================
*/
@mixin outer-padding() {
  box-sizing : border-box;
  padding    : 0 48px;

  @include media($medium) {
    padding : 0 24px;
  }

  @include media($small) {
    padding : 0 12px;
  }
}
