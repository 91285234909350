.c-accordion {
  &__title {
    @include ts-heading-2;
    border-top : 6px solid $purple;
    cursor        : pointer;
    padding    : 18px 58px 18px 0;
    position      : relative;

    @include media ($small) {
      padding-right : 52px;
    }

    .c-content__main & {
      margin-bottom : 0;
    }

    &__icon {
      @include svg_image(accordion-arrow, 41px 24px, 0, 0);
      display    : block;
      height     : 24px;
      margin-top : -12px;
      position   : absolute;
      right      : 0;
      top        : 50%;
      transition : transform 0.3s;
      width      : 41px;

      @include media ($medium) {
        @include svg_image(accordion-arrow, 34px 20px, 0, 0);
        width  : 34px;
        height : 20px;
      }

      @include media ($small) {
        @include svg_image(accordion-arrow, 27px 16px, 0, 0);
        width  : 27px;
        height : 16px;
      }
    }

    &:focus{
      border-color:$yellow;
      outline:0;
    }
  }

  &.is-open &__title__icon {
    transform : rotate(180deg);
  }

  &__content {
    @include default-list-style;
    display : none;
    padding : 12px 0;

    h2, h3 {
      margin-bottom : 24px;
    }

    h4, h5 {
      margin-bottom : 12px;
    }

    p {
      margin-bottom : 24px;
    }

    button {
      margin-bottom : 36px;
    }
  }
}
