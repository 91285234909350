.c-advice-output {
  margin: 24px 0 36px;

  @include default-list-style;

  @include media($large) {
    @include span-columns(8);
  }

  @include media($medium) {
    @include span-columns(4);
  }

  @include media($small) {
    @include span-columns(4);
    margin: 0;
  }

  p {
    @include ts-body;
  }

  p + .c-accordion {
    margin-top: 24px;
  }

  ol, ul {
    @include ts-body;
  }

  h1 {
    @include ts-heading-1;
  }

  h2 {
    @include ts-heading-2;
  }

  h3 {
    @include ts-heading-3;
  }

  h4 {
    @include ts-heading-4;
  }

  h5 {
    @include ts-heading-5;
  }

  &__non-accordion {
    h2, h3 {
      margin-bottom: 24px;
    }

    h4, h5 {
      margin-bottom: 12px;
    }

    p {
      margin-bottom: 24px;
    }
  }

  & > p,
  & > ul,
  & > ol {
    margin-bottom: 24px;
  }

  &__border-top {
    border-top: 6px solid $purple;
    padding-top: 48px;
  }
}
