.ui-autocomplete {
  padding          : 0;
  list-style       : none;
  background-color : #FFFFFF;
  width            : 218px;
  border           : 1px solid #B0BECA;
  max-height       : 350px;
  overflow-x       : hidden;
}
.ui-autocomplete .ui-menu-item {
  border-top : 1px solid #B0BECA;
  display    : block;
  padding    : 4px 6px;
  color      : #353D44;
  cursor     : pointer;
}
.ui-autocomplete .ui-menu-item:first-child {
  border-top : none;
}
.ui-autocomplete .ui-menu-item.ui-state-focus {
  background-color : #D5E5F4;
  color            : #161A1C;
}
/*! jQuery UI - v1.11.0 - 2014-08-12
* http://jqueryui.com
* Includes: core.css, autocomplete.css, menu.css
* Copyright 2014 jQuery Foundation and other contributors; Licensed MIT */

/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display : none;
}
.ui-helper-hidden-accessible {
  border   : 0;
  clip     : rect(0 0 0 0);
  height   : 1px;
  margin   : -1px;
  overflow : hidden;
  padding  : 0;
  position : absolute;
  width    : 1px;
}
.ui-helper-reset {
  margin          : 0;
  padding         : 0;
  border          : 0;
  outline         : 0;
  line-height     : 1.3;
  text-decoration : none;
  font-size       : 100%;
  list-style      : none;
}
.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content         : "";
  display         : table;
  border-collapse : collapse;
}
.ui-helper-clearfix:after {
  clear : both;
}
.ui-helper-clearfix {
  min-height : 0; /* support: IE7 */
}
.ui-helper-zfix {
  width    : 100%;
  height   : 100%;
  top      : 0;
  left     : 0;
  position : absolute;
  opacity  : 0;
  filter   : Alpha(Opacity=0);
}
.ui-front {
  z-index : 100;
}
/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor : default !important;
}
/* Icons
----------------------------------*/

/* states and images */
.ui-icon {
  display           : block;
  text-indent       : -99999px;
  overflow          : hidden;
  background-repeat : no-repeat;
}
/* Misc visuals
----------------------------------*/

/* Overlays */
.ui-widget-overlay {
  position : fixed;
  top      : 0;
  left     : 0;
  width    : 100%;
  height   : 100%;
}
.ui-autocomplete {
  position : absolute;
  top      : 0;
  left     : 0;
  cursor   : default;
}
.ui-menu {
  list-style : none;
  padding    : 0;
  margin     : 0;
  display    : block;
  outline    : none;
}
.ui-menu .ui-menu {
  position : absolute;
}
.ui-menu .ui-menu-item {
  position         : relative;
  margin           : 0;
  padding          : 3px 1em 3px .4em;
  cursor           : pointer;
  min-height       : 0; /* support: IE7 */
  /* support: IE10, see #8844 */
  list-style-image : url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
}
.ui-menu .ui-menu-divider {
  margin       : 5px 0;
  height       : 0;
  font-size    : 0;
  line-height  : 0;
  border-width : 1px 0 0 0;
}
/* icon support */
.ui-menu-icons {
  position : relative;
}
.ui-menu-icons .ui-menu-item {
  padding-left : 2em;
}
/* left-aligned */
.ui-menu .ui-icon {
  position : absolute;
  top      : 0;
  bottom   : 0;
  left     : .2em;
  margin   : auto 0;
}
/* right-aligned */
.ui-menu .ui-menu-icon {
  left  : auto;
  right : 0;
}
