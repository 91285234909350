.c-footer {
  background : $pale-yellow;
  border-top : 3px solid $purple;
  padding    : 12px 0 48px;

  @include media($small) {
    padding-top : 24px;
  }

  &__container {
    @include outer-container();
    @include outer-padding();
  }

  &__inner {
    @include span-columns(12);
  }

  &__left {
    @include span-columns(5);

    @include media($medium) {
      @include span-columns(3);
    }

    @include media($small) {
      @include span-columns(4);
      margin-bottom : 12px;
    }
  }

  &__right {
    @include span-columns(7);
    text-align : right;

    @include media($medium) {
      @include span-columns(3);
    }

    @include media($small) {
      @include span-columns(4);
      text-align : left;
    }
  }

  &__links {
    li {
      display        : inline-block;
      margin-right   : 12px;
      vertical-align : top;

      @include media($small) {
        display : block;
      }
    }
  }
}
