.c-question {
  @include outer-container();
  padding-bottom : 36px;

  &__container {
    @include span-columns(8);

    @include media ($medium) {
      @include span-columns(4);
    }

    @include media ($small) {
      @include span-columns(4);
    }
  }

  &__question {
    margin-bottom : 24px;
  }

  &__option {
    display       : table;
    margin-bottom : 12px;
    min-height    : 54px;

    &__input {
      @include checkbox-hide;

      &:checked + label,
      &:hover + label {
        background-color : $yellow;

        &:before {
          background-color : $purple;
        }
      }

      &:focus + label {
        background-color : $yellow;
      }
    }

    &__label {
      border         : 3px solid $purple;
      cursor         : pointer;
      display        : table-cell;
      padding        : 8px 15px 10px 48px;
      position       : relative;
      transition     : background-color 0.3s;
      vertical-align : middle;

      @include media ($small) {
        padding : 8px 15px 10px 46px;
      }

      &:before {
        border        : 3px solid $purple;
        border-radius : 50%;
        content       : "";
        height        : 18px;
        left          : 15px;
        margin-top    : -9px;
        position      : absolute;
        top           : 50%;
        transition    : background-color 0.3s;
        width         : 18px;

        @include media ($small) {
          height     : 16px;
          margin-top : -8px;
          width      : 16px;
        }
      }
    }

    &:last-child {
      margin-bottom : 0;
    }
  }

  &__alert {
    margin-bottom : 24px;
  }
}
